<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>{{ menuTitle }}</template>
    </breadcrumb-nav>
    <el-card :inline="true" shadow="never" style="margin-bottom: 10px">
      <el-alert style="margin-bottom: 10px;" type="warning" title="注意：添加商品前请先选择分类和店铺" :closable="false"
                show-icon></el-alert>
      <el-row :gutter="5">
        <el-col :span="6">
          <!--<category-select ref="category" :forbidden="forbiddenCategorySelect" @categoryChange="categoryChange" :set-default="categoryId"></category-select>-->
          <cate-select ref="category" :forbidden="forbiddenCategorySelect" @categoryChange="categoryChange" :set-default="categoryId"></cate-select>
        </el-col>
        <el-col :span="6">
          <shop-select ref="shop" :forbidden="forbiddenShopSelect" @shopChange="shopChange" :set-default="shopId"></shop-select>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="submitGoods">确认提交商品</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-form label-width="100px" size="small" label-position="left">
        <el-tabs v-model="activeName" @tab-click="tabClicked">
          <el-tab-pane label="基本信息" name="base" :disabled="disablePane">
            <goods-base-pane ref="base" @locationChange="locationChange"></goods-base-pane>
          </el-tab-pane>
          <el-tab-pane label="规格设置" name="specs" :disabled="disablePane">
            <goods-specs-attr-pane ref="specs" :attrs-list="specsAttrs"></goods-specs-attr-pane>
          </el-tab-pane>
          <el-tab-pane label="展示设置" name="show" :disabled="disablePane">
            <goods-show-attr-pane ref="show" :attrs-list="showAttrs" :term-list="termList"></goods-show-attr-pane>
          </el-tab-pane>
          <el-tab-pane label="图片" name="image" :disabled="disablePane">
            <goods-gallery-pane ref="gallery" :swiper-list="swiperList" :detail-list="detailList"></goods-gallery-pane>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import GoodsBasePane from './GoodsBasePane'
// import CategorySelect from '../../common/CategorySelect'
import ShopSelect from '../../common/ShopSelect'
import GoodsShowAttrPane from './GoodsShowAttrPane'
import { addGoodsRequest, listAttributeRequest, ListSaleTermRequest } from '../../../network/goods'
import GoodsSpecsAttrPane from './GoodsSpecsAttrPane'
import GoodsGalleryPane from './GoodsGalleryPane'
import CateSelect from '../../common/CateSelect'
const NewBuyerGoodsLocation = 9
export default {
  name: 'GoodsAdd',
  components: {
    CateSelect,
    GoodsSpecsAttrPane,
    BreadcrumbNav,
    GoodsBasePane,
    // CategorySelect,
    ShopSelect,
    GoodsShowAttrPane,
    GoodsGalleryPane
  },
  data () {
    return {
      menuTitle: '添加商品', // 顶部标题
      shopId: 0, // 店铺id
      categoryId: 0, // 分类id
      activeName: 'base', // 当前tab
      disablePane: true, // tabPane是否禁止点击
      showAttrs: [], // 参数属性
      specsAttrs: [], // 规格属性
      termList: [], // 保障条款,
      forbiddenCategorySelect: false,
      forbiddenShopSelect: false,
      swiperList: [], // 录播图列表
      detailList: [] // 详情图列表
    }
  },
  mounted () {
    console.log('this.$route.params.goods', this.$route.query)
    if (this.$route.query.goodsId !== undefined) {
      this.menuTitle = '更新商品'
      this.initGoods(this.$route.query)
    }
  },
  methods: {
    // 更新商品时初始化信息
    initGoods (goods) {
      console.log('init goods, goodsId:', goods.goodsId, ', categoryId:', goods.categoryId, ', shopId:', goods.shopId)
      this.initSelect(goods)
    },
    initSelect (goods) {
      this.categoryId = parseInt(goods.categoryId)
      this.shopId = parseInt(goods.shopId)
      this.forbiddenCategorySelect = true
      this.forbiddenShopSelect = true
    },
    locationChange (val) {
      console.log('add goods location:', val)
      if (val === NewBuyerGoodsLocation) {
        this.$refs.specs.isNewBuyerGoods = true
      } else {
        this.$refs.specs.isNewBuyerGoods = false
      }
    },
    // 点击按钮添加商品
    submitGoods () {
      this.$confirm('是否要提交该商品', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doCommit()
      }).catch(err => {
        console.log('confirm err:', err)
        this.alertMessage('已取消提交', 'info')
      })
    },
    doCommit () {
      this.$refs.base.validCommit(valid => {
        if (!valid) {
          return this.alertMessage('基本信息不完整', 'error')
        }
        this.commit2Srv()
      })
      // this.commit2Srv()
    },
    resetAddGoods () {
      this.$refs.base.reset()
      // 获取参数属性
      this.getShowAttr()
      // 获取服务条款
      this.getTermList()
      // 获取规格属性
      // this.getSpecsAttr()
      this.$refs.specs.resetSku()
      this.swiperList = []
      this.detailList = []
      this.$refs.gallery.reset()
    },
    commit2Srv () {
      const base = this.$refs.base.getItem()
      base.categoryId = this.categoryId
      base.shopId = this.shopId
      // this.resetAddGoods()
      // return
      // eslint-disable-next-line no-unreachable
      const sku = this.$refs.specs.getSku()
      if (!sku.valid) {
        return
      }
      console.log('commit specs form:', sku)
      const showData = this.$refs.show.getShowData()
      const gallery = this.$refs.gallery.getGallery()
      if (!gallery.valid) {
        return
      }
      console.log('commit base form:', base)
      console.log('commit show form:', showData)
      console.log('commit specs form:', sku)
      console.log('commit gallery form:', gallery)
      addGoodsRequest({
        base: base,
        ext: {
          showAttrs: showData.showAttrs,
          terms: showData.terms,
          galleries: {
            swiper: gallery.swiper,
            detail: gallery.detail
          }
        },
        specs: {
          specs: sku.specs,
          skuList: sku.skuList
        }
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加商品失败', 'error')
        }
        this.resetAddGoods()
        console.log('commit goods to srv succ')
        this.alertMessage('添加商品成功', 'success')
      })
    },
    // 分类改变，获取属性列表
    categoryChange (categoryId) {
      this.categoryId = categoryId
      console.log('this.categoryId:', this.categoryId)
      if (this.shopId !== 0) {
        this.disablePane = false
        this.$refs.base.disabled = false
      }
      this.resetAddGoods()
    },
    shopChange (shopId) {
      console.log('this.shopId:', this.shopId)
      this.shopId = shopId
      if (this.categoryId !== 0) {
        this.disablePane = false
        this.$refs.base.disabled = false
      }
      this.resetAddGoods()
    },
    tabClicked (tab, event) {
      // console.log('tab:', tab.name, ';event:', event)
    },
    getTermList () {
      ListSaleTermRequest({}).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取条款列表获取失败', 'error')
        }
        this.termList = result.data.list
      })
    },
    getShowAttr () {
      listAttributeRequest(this.categoryId, 'only').then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取参数失败', 'error')
        }
        this.showAttrs = result.data.list
      })
    },
    getSpecsAttr () {
      listAttributeRequest(this.categoryId, 'many').then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取参数失败', 'error')
        }
        this.specsAttrs = []
        const specsAttrs = result.data.list
        for (const attr of specsAttrs) {
          const specsAttr = {
            name: attr.name,
            id: attr.id,
            sortNum: attr.sortNum,
            checkedVals: []
          }
          const attrVals = []
          for (const val of attr.attrVals) {
            attrVals.push({
              val: val,
              checked: false
            })
          }
          specsAttr.attrVals = attrVals
          this.specsAttrs.push(specsAttr)
        }
        console.log('specs attr handle:', this.specsAttrs)
      })
    }
  }
}
</script>

<style>
.el-input-number.is-without-controls .el-input__inner {
  width: 100%;
  line-height: 30px;
  height: 28px;
}
</style>
